import { gql } from "@apollo/client";
import { FRAGMENT_INGREDIENT } from "./ingredients";
export const FRAGMENT_RECIPE = gql`
  fragment Recipedetails on Recipe {
    id
    created_at
    updated_at
    title
    recipeTitle
    details
    ingredients
    instructions
    imageSrc
    url
    nutritions
    calori
    published_at
    recipetype
    recipe_tags {
      id
      name
    }
    ingredientObjs {
      ...Ingredientdetails
    }
    cost
  }
  ${FRAGMENT_INGREDIENT}
`;
export const FRAGMENT_RECIPES = gql`
  fragment Recipesdetails on Recipe {
    id
    title
    recipeTitle
    imageSrc
    ingredients
    url
    created_at
    recipe_tags {
      id
      name
    }
  }
`;
export const FRAGMENT_RECIPE_SC = gql`
fragment BIngredientDetail on BIngredientDetail {
    ingIds
    ymkId
    Name
    Note
    Media
    ImagePathSmall
    ImagePathLarge
    details
  }
  fragment BIngredientSC on BIngredient {
    name
    list {
      ...BIngredientDetail
    }
  }
  
  fragment RecipedetailsSC on ScrapperObject {
    url
    title
    imageSrc
    video
    recipeTitle
    details {
      text
      number
    }
    ingredients {
      ...BIngredientSC
    }
    instructions {
      text
      img
    }
    nutritions
    calori
    recipetype
    recipe_tags {
      id
      name
    }
  }
`;

export const GET_RECIPES = gql`
  query Recipes($input: JSON) {
    recipes(where: $input) {
      ...Recipesdetails
    }
  }
  ${FRAGMENT_RECIPES}
`;

export const GET_RECIPE = gql`
  query Recipe($id: ID!) {
    recipe(id: $id) {
      ...Recipedetails
    }
  }
  ${FRAGMENT_RECIPE}
`;

export const POST_RECIPE = gql`
  mutation PostRecipeMutation($data: RecipeInput!) {
    createRecipe(input: { data: $data }) {
      recipe {
        id
        created_at
        updated_at
        title
        recipeTitle
        details
        ingredients
        instructions
        imageSrc
        url
        nutritions
        calori
        published_at
        recipetype
        recipe_tags {
          id
          name
        }
      }
    }
  }
`;

export const GET_RECIPE_TAGS = gql`
  query {
    recipeTags {
      id
      name
      recipes {
        id
      }
    }
  }
`;

export const POST_RECIPE_TAGS = gql`
  mutation PostRecipeTagsMutation($data: RecipeTagInput!) {
    createRecipeTag(input: { data: $data }) {
      recipeTag {
        id
        name
      }
    }
  }
`;
