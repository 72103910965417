import { gql } from "@apollo/client";
// import { FRAGMENT_RECIPE } from "./recipes";

export const FRAGMENT_INGREDIENT = gql`
  fragment Ingredientdetails on Ingredient {
    id
    name
    key
    aliases
    # definedUnits
    # recurrenceStr
    image
    # price
    # nutritions
    # updated_at
  }
`;

export const GET_INGREDIENTS = gql`
  query {
    ingredients {
      ...Ingredientdetails
    }
  }
  ${FRAGMENT_INGREDIENT}
`;
export const GET_INGREDIENT = gql`
  query Ingredients($id: ID!) {
    ingredient(id: $id) {
      ...Ingredientdetails
    }
  }
  ${FRAGMENT_INGREDIENT}
`;

export const GET_INGREDIENTSDATA = gql`
  query {
    ingredientsDatum {
      lineunitlist
      ingredientsMatch
    }
  }
`;
export const ADD_INGREDIENT_MUTATION = gql`
  mutation AddIngredientMutation($data: IngredientInput!) {
    createIngredient(input: { data: $data }) {
      ingredient {
        id
        name
        key
        aliases
        # definedUnits
        # recurrenceStr
        image
        # price
        # nutritions
        # updated_at
      }
    }
  }
`;
